import FuseUtils from "@fuse/utils";
import AppContext from "app/AppContext";
import { Component } from "react";
import { connect } from "react-redux";
import { matchRoutes } from "react-router-config";
import { withRouter } from "react-router-dom";

class FuseAuthorization extends Component {
  constructor(props, context) {
    super(props);
    console.log('context',context);
    const { routes } = context;
    const { loggedIn } = context;
    this.state = {
      accessGranted: true,
      routes,
      isAuthenticated: context.auth.isAuthenticated,
    };
  }

  componentDidMount() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.accessGranted !== this.state.accessGranted;
  }

  componentDidUpdate() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { location, userRole } = props;
    const { isAuthenticated } = state;
    const { pathname } = location;

    const matched = matchRoutes(state.routes, pathname)[0];
    return {
      accessGranted:isAuthenticated,
    };
  }
  redirectRoute() {
    const { location, userRole, history } = this.props;
    const { isAuthenticated } = this.state;
    const { pathname, state } = location;
    const redirectUrl = state && state.redirectUrl ? state.redirectUrl : "/";
    if (isAuthenticated == false) {
      history.push({
        pathname: "/pages/auth/login",
      });
    } else {
      history.push({
        pathname: "/pages/organisations/organisationlist",
      });
    }
  }

  render() {
    // console.info('Fuse Authorization rendered', this.state.accessGranted);
    return  <>{this.props.children}</>
  }
}

function mapStateToProps({ auth }) {
  return {
    userRole: auth.user.role,
  };
}

FuseAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps)(FuseAuthorization));
