// import createGenerateClassName from '@mui/styles/createGenerateClassName';
// import jssPreset from '@mui/styles/jssPreset';
// import { create } from 'jss';
// import jssExtend from 'jss-plugin-extend';
// import rtl from 'jss-rtl';
import Provider from 'react-redux/es/components/Provider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { StyledEngineProvider } from '@mui/material/styles';
import { useContext, useState, useReducer } from 'react';
import AppContext from './AppContext';
import routes from './fuse-configs/routesConfig';
import store from './store';
import { AuthContext } from './main/context/authcontext';

const AuthReducer = (state, action) => {
  switch (action.type) {
    case 'isAuthenticated':
      localStorage.setItem('isAuthenticated', true);
      localStorage.setItem('accessToken', action.payload.accessToken);
      return {
        ...state,
        isAuthenticated: true,
        access_token: action.payload.accessToken,
        userDetails: { email: action.payload.email, name: action.payload.name },
      };
    case 'refresh_token':
      return { ...state, refresh_token: action.payload };
    case 'details':
      return { ...state, details: action.payload };
    case 'logout':
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('accessToken');
      return { ...state, isAuthenticated: false, accessToken: null };
    case 'toggleMode': {
      const ip =
        state.production === true
          ? 'http://52.66.208.124:9080/api/tl'
          : 'http://3.110.152.76/api/tl';
      return { ...state, production: !state.production, baseIP: ip };
    }
    default:
      return state;
  }
};

const withAppProviders = (Component) => (props) => {
  const initialValue = {
    isAuthenticated: !!localStorage.getItem('isAuthenticated'),
    access_token: null,
    refresh_token: null,
    additionalDetails: null,
    userDetails: {
      email: null,
      name: null,
    },
    production: true,
    baseIP: 'http://3.110.152.76/api/tl',
  };

  const WrapperComponent = () => {
    const [auth, dispatch] = useReducer(AuthReducer, initialValue);
    const authContext = useContext(AuthContext);
    return (
      <AppContext.Provider
        value={{
          routes,
          auth,
          dispatch,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Provider store={store}>
            <StyledEngineProvider injectFirst>
              <Component {...props} />
            </StyledEngineProvider>
          </Provider>
        </LocalizationProvider>
      </AppContext.Provider>
    );
  };

  return WrapperComponent;
};

export default withAppProviders;
