import { lazy } from 'react';

const  organisationAppConfig= {
    settings: {
      layout: {
        config: {},
      },
    },
    routes: [
      {
        path: '/pages/organisations/organisationlist',
        component: lazy(() => import('../organisationList')),
      },
      {
        path: '/pages/organisations/neworganisation',
        component: lazy(() => import('../OrganisationForm')),
      }, 
      {
        path: '/pages/organisations/&orgId=:orgId/organisationDetails',
        component: lazy(() => import('../organisationDetails')),
      },
      {
        path: '/pages/organisations/&orgId=:orgId/updateOrganisation',
        component: lazy(() => import('../updateOrganisation')),
      }, 
      {
        path: '/pages/organisations/logout',
        component: lazy(() => import('../logout')),
      },
      {
        path: '/pages/organisations/&orgId=:orgId/reset',
        component: lazy(() => import('../reset')),
      },    
      {
        path: '/pages/organisations/&orgId=:orgId/updateOrganisationImage',
        component: lazy(() => import('../UpdateOrgImage')),
      }, 
    ],
  };
  
export default organisationAppConfig;