import OrganisationFormDialog from "app/main/components/organisation/OrganisationFormDialog";
import useFetch from "app/main/hooks/useFetch";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import FuseLoading from "@fuse/core/FuseLoading/FuseLoading";
import Backdrop from '@mui/material/Backdrop';
import toast from 'react-hot-toast';
import { useContext } from "react";
import AppContext from "app/AppContext";


const UpdateOrganisation = () => {
  const { auth } = useContext(AppContext);
  const { baseIP } = auth;
  const routeParams = useParams();
  const { orgId } = routeParams;
  const history = useHistory();
  const [dataloading,setLoading]=useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const url = `${baseIP}/admin/edit/${orgId}`;
  const initialValues = {};

  useEffect(() => {
    if(openDialog==false) {
    setOpenDialog(true);
    }
  }, [openDialog]);


  const {
    loading,
    error,
    data: orgData,
  } = useFetch(`${baseIP}/admin/${orgId}`);

  if(loading) {
    return <FuseLoading />
  }

  if (orgData.status === 500) {
    history.push('/pages/organisations/organisationlist');
    return <p>Error</p>
  }
  
  

  if (orgData) {
    const values = {
      id: orgData.id ? orgData.id : "",
      firstName: orgData.firstName ? orgData.firstName : "",
      lastName: orgData.lastName ? orgData.lastName : "",
      primaryPhone: orgData.primaryPhone ? orgData.primaryPhone : "",
      emailId: orgData.emailId ? orgData.emailId : "",
      orgName: orgData.orgName ? orgData.orgName : "",
      address: orgData.orgAddress?.address ? orgData.orgAddress?.address : "",
      district: orgData.orgAddress?.district ? orgData.orgAddress?.district : "",
      state: orgData.orgAddress?.state ? orgData.orgAddress?.state : "",
      postalCode: orgData.orgAddress?.postalCode ? orgData.orgAddress?.postalCode: "",
      secondaryPhone: orgData.secondaryPhone ? orgData.secondaryPhone : "",
      hipId: orgData.hipId ? orgData.hipId : "",
      clientId: orgData.clientId
        ? clientPassword
        : orgData.clientPassword
        ? orgData.clientPassword
        : "",
      bridgeUrl: orgData.bridgeUrl ? orgData.bridgeUrl : "",
      password: orgData.password ? orgData.password : "",
    };
    Object.assign(initialValues, values);
  }

  
  const closeDialog = () => {
    setOpenDialog(false);
    history.push('/pages/organisations/organisationlist');
  };


  const handleSubmit = async (values) => {
    setLoading(true);
    const body = {
      id: values.id ? values.id : "",
      firstName: values.firstName ? values.firstName : "",
      lastName: values.lastName ? values.lastName : "",
      primaryPhone: values.primaryPhone ? values.primaryPhone : "",
      emailId: values.emailId ? values.emailId : "",
      orgName: values.orgName ? values.orgName : "",
      orgAddress: {
        address: values.address,
        district: values.district,
        state: values.state,
        postalCode: values.postalCode,
      },
      orgLogo: "string",
      secondaryNumber: values.secondaryNumber ? values.secondaryNumber : "",
      hipId: values.hipId ? values.hipId : "",
      clientID: values.clientID ? values.clientID : "",
      clientPassword: values.clientPassword ? values.clientPassword : "",
      bridgeUrl: values.bridgeUrl ? values.bridgeUrl : "",
      password: values.password ? values.password : "",
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    try {
      const response = await axios.put(url, body, { headers });
      if (response.status === 200 || response.status === 201 || response.status === 202) {
        console.log('response', response.data);
        setLoading(false);
        toast.success('Organisation Updated Successfully');
        closeDialog();
      }
    } catch {
      console.log("error", error);
      setLoading(false);
      toast.error("Something went wrong");
      closeDialog();
      // setLoading(false);
    }
  };

  if (loading === true) {
    return <FuseLoading />;
  }

  
  return (
    <>
    {
      initialValues && 
      <OrganisationFormDialog
      initialValues={initialValues}
      onSubmit={handleSubmit}
      openDialog={openDialog}
      closeDialog={closeDialog}
      activeStep={0}
      title={"Update Organisation"}
      backUrl="/pages/organisations/organisationlist"
    />     
    }    
    {dataloading==true && 
    <>
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={dataloading}
  >
    <FuseLoading />
  </Backdrop>
  </>}
    </>
  );
};

export default UpdateOrganisation;
