import { yupResolver } from '@hookform/resolvers/yup';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import axios from 'axios';
import { useContext, useState } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { FileUploader } from 'react-drag-drop-files';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import toast from 'react-hot-toast';
import AppContext from 'app/AppContext';

const steps = ['Fill Organisation Details', 'Upload Logo'];
const schema = yup.object().shape({
  id: yup.string().required('Required'),
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  primaryPhone: yup
    .number('Required')
    .typeError('Required')
    .positive('Required')
    .integer('Required')
    .required('Required'),
  emailId: yup
    .string()
    .email('Please enter a valid email address')
    .required('Required'),
  orgName: yup.string().required('Required'),
  address: yup.string().required('Required'),
  district: yup.string().required('Required'),
  state: yup.string().required('Required'),
  postalCode: yup
    .number('Required')
    .typeError('Required')
    .positive('Required')
    .integer('Required')
    .required('Required'),
  hipId: yup.string().required('Required'),
  password: yup.string().required('Required'),
});

function OrganisationFormDialog({
  title,
  backUrl,
  activeStep,
  initialValues,
  onSubmit,
  openDialog,
  closeDialog,
}) {
  const fileTypes = ['JPG', 'PNG', 'GIF'];
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [draggingFiles, setDraggingFiles] = useState(false);
  const [file, setFile] = useState(null);
  const {
    control,
    watch,
    reset,
    formState,
    handleSubmit,
    getdata,
    validationResolver,
  } = useForm({
    mode: 'onChange',
    initialValues,
    resolver: yupResolver(schema),
    validationResolver: (values, context, options) => {
      console.log('validatio-values', values);
      return {
        values: values,
        errors: {},
      };
    },
  });
  const { isValid, dirtyFields, errors } = formState;
  const handleChangeFile = (file) => {
    setFile(file);
  };

  const { auth } = useContext(AppContext);
  const { baseIP } = auth;

  const handleSubmitform = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    const headers = {
      'Content-Type': 'application/JSON',
    };
    try {
      const response = await axios.post(
        `http://${baseIP}/api/tl/admin/create`,
        formData,
        { headers }
      );
      setLoading(false);
      if (
        response.status == 200 ||
        response.status == 201 ||
        response.status == 202
      ) {
        toast.success('Organisation Created Successfully');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error('something went wrong');
    }
  };

  return (
    <Dialog
      onClose={closeDialog}
      fullWidth
      open={openDialog}
      maxWidth="md"
    >
      <AppBar
        position="static"
        elevation={0}
      >
        <Toolbar className="flex w-full">
          <Typography
            variant="subtitle1"
            color="inherit"
          >
            {title}
          </Typography>
        </Toolbar>
        <div className="flex flex-col items-center justify-center pb-20" />
        <div
          style={{
            width: '50%',
            margin: 'auto',
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          {/* <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={0}>
                <StepButton color="primary">{label}</StepButton>
              </Step>
            ))}
          </Stepper> */}
        </div>
      </AppBar>

      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col md:overflow-hidden"
      >
        <DialogContent classes={{ root: 'p-24' }}>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-2">
            <div className="flex">
              <Controller
                control={control}
                name="id"
                defaultValue={initialValues.id}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="ID"
                    type="text"
                    id="id"
                    error={!!errors.id}
                    helperText={errors?.id?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="flex">
              <Controller
                defaultValue={initialValues.firstName}
                control={control}
                name="firstName"
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="First Name"
                    id="name"
                    type="text"
                    error={!!errors.firstName}
                    helperText={errors?.firstName?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="flex">
              <Controller
                control={control}
                name="lastName"
                defaultValue={initialValues.lastName}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Last name"
                    id="lastName"
                    type="text"
                    error={!!errors.lastName}
                    helperText={errors?.lastName?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="flex">
              <Controller
                control={control}
                defaultValue={initialValues.primaryPhone}
                name="primaryPhone"
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Primary Phone Number"
                    id="primaryPhone"
                    type="number"
                    variant="outlined"
                    error={!!errors.primaryPhone}
                    helperText={errors?.primaryPhone?.message}
                    required
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="flex">
              <Controller
                control={control}
                name="emailId"
                defaultValue={initialValues.emailId}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Email ID"
                    id="emailId"
                    variant="outlined"
                    required
                    error={!!errors.emailId}
                    helperText={errors?.emailID?.message}
                    type="email"
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="flex">
              <Controller
                control={control}
                name="orgName"
                defaultValue={initialValues.orgName}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Organisation Name"
                    id="orgName"
                    type="text"
                    variant="outlined"
                    error={!!errors.orgName}
                    helperText={errors?.orgName?.message}
                    required
                    fullWidth
                  />
                )}
              />
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="address"
                defaultValue={initialValues.address}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Address"
                    id="address"
                    type="text"
                    variant="outlined"
                    error={!!errors.address}
                    helperText={errors?.address?.message}
                    required
                    fullWidth
                  />
                )}
              />
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="district"
                defaultValue={initialValues.district}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="District"
                    type="text"
                    id="district"
                    variant="outlined"
                    error={!!errors.district}
                    helperText={errors?.district?.message}
                    required
                    fullWidth
                  />
                )}
              />
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="state"
                defaultValue={initialValues.state}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="State"
                    type="text"
                    id="state"
                    variant="outlined"
                    error={!!errors.state}
                    helperText={errors?.state?.message}
                    required
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="flex">
              <Controller
                control={control}
                name="postalCode"
                defaultValue={initialValues.postalCode}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    type="number"
                    label="Postal Code"
                    id="postalCode"
                    error={!!errors.postalCode}
                    helperText={errors?.postalCode?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="password"
                defaultValue={initialValues.password}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Password"
                    type="password"
                    id="password"
                    error={!!errors.password}
                    helperText={errors?.password?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="hipId"
                defaultValue={initialValues.hipId}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Hip ID"
                    id="hipId"
                    type="text"
                    error={!!errors.hipId}
                    helperText={errors?.hipId?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="clientID"
                defaultValue={initialValues.clientID}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Client ID"
                    type="text"
                    id="clientID"
                    variant="outlined"
                    error={!!errors.clientID}
                    helperText={errors?.clientID?.message}
                    fullWidth
                  />
                )}
              />
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="clientPassword"
                defaultValue={initialValues.clientPassword}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Client Password"
                    type="password"
                    id="clientPassword"
                    variant="outlined"
                    error={!!errors.clientPassword}
                    helperText={errors?.clientPassword?.message}
                    fullWidth
                  />
                )}
              />
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="bridgeUrl"
                type="email"
                defaultValue={initialValues.bridgeUrl}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    label="Bridge URL"
                    id="bridgeUrl"
                    error={!!errors.bridgeUrl}
                    helperText={errors?.bridgeUrl?.message}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </div>

            <div className="flex">
              <Controller
                control={control}
                name="secondaryNumber"
                defaultValue={initialValues.secondaryNumber}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    type="number"
                    label="Secondary Number"
                    id="secondaryNumber"
                    variant="outlined"
                    error={!!errors.secondaryNumber}
                    helperText={errors?.secondaryNumber?.message}
                    fullWidth
                  />
                )}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className="justify-between p-4 pb-16">
          <div className="px-16">
            <Button
              onClick={() => history.push(`${backUrl}`)}
              variant="contained"
              color="primary"
            >
              Cancel
            </Button>
          </div>
          <div className="px-16">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={!isValid}
            >
              Submit
            </Button>
          </div>
        </DialogActions>
      </form>

      {/* {activeStep == 1 && (
        <div>
          <form
            noValidate
            onSubmit={handleSubmit(handleSubmitform)}
            className="flex flex-col md:overflow-hidden"
            enctype="multipart/form-data"
          >
            <div
              style={{
                minHeight: "500px",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 30,
              }}
            >
              <div></div>
              <FileUploader
                handleChange={handleChangeFile}
                name="file"
                types={fileTypes}
                onDraggingStateChange={() => setDraggingFiles(true)}
              />
              <Button
                type="submit"
                variant="contained"
                color="success"
                disabled={file == null}
                onClick={handleSubmitform}
              >
                Upload Image and Submit
              </Button>
            </div>
          </form>
          <DialogActions className="justify-between p-4 pb-16">
            <div className="px-16">
              <Button
                onClick={() => history.push(`${backUrl}`)}
                variant="contained"
                color="primary"
              >
                Cancel
              </Button>
            </div>
            <div className="px-16"></div>
          </DialogActions>
        </div>
      )} */}
      {loading === true && <FuseLoading />}
    </Dialog>
  );
}

export default OrganisationFormDialog;
